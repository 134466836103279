@import '../.resources/styles/footer-layout.css';

:root {
  --app-color-brand: #00f8a3;
  --app-color-light: #cedbda;

  --app-color-white: #fff;
  --app-color-black: #000B0A;

  --app-background: #091417;

  --app-header-height: 56px;
  --app-header-background: var(--app-background);

  --app-color-neutral: #84a5a2;
  --app-color-neutral-white: var(--app-color-light);
  --app-color-neutral-darker: var(--app-color-neutral);

  --app-color-shadow-line: hsla(189, 9%, 15%, 0.15);
  --app-shadow-low: 0 0 1px #cdcbcb;
  --app-shadow-mid: 0 2px 4px var(--app-color-shadow-line);
  --app-shadow-high: 0 5px 10px var(--app-color-shadow-line);

  --app-footer-background: var(--app-color-black);
  --app-footer-text: var(--app-color-white);
}

@font-face {
  font-family: headlines;
  font-display: swap;
  src: url("../.resources/fonts/gilroy/gilroy-extrabold.woff2") format("woff2"),
    url("../.resources/fonts/gilroy/gilroy-extrabold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

body,
input,
textarea,
select,
button {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
  line-height: 1.5;
  color: var(--app-color-white);
}

h1,
h2,
h3 {
  font-family: headlines, Arial, Helvetica, sans-serif;
  letter-spacing: -0.03em;
  line-height: 1.1;
  color: var(--app-color-white) !important;
  text-transform: uppercase;
}

body {
  background: var(--app-background);
}

header {
  color: var(--app-color-white);
}

.app-release-status__info,
.app-contact-details__content {
  background-color: var(--app-color-black) !important;
  color: var(--app-color-light);
}

.app-logo {
  background-image: url("../.resources/svgs/logo-speedybet-es.svg");
  margin-left: 16px !important;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
  display: block;
  width: 158px;
  height: 100%;
}

.app-contact-details__content {
  border-radius: 0 0 .7em .7em;
  margin-top: 0;
  border-top: 1px solid var(--app-color-neutral);
}

.app-contact-details-list__info {
  margin-bottom: .3em;
}

.app-release-status__subtitle {
  padding-top: .5em;
}

.app-locale-selector a {
  padding: 0 0 0 1em !important;
  height: 48px;
  display: flex !important;
  align-items: center;
}

.app-locale-selector a.router-link-exact-active {
  color: var(--app-color-brand) !important;
}

.app-locale-selector nav {
  background: var(--app-color-black) !important;
}
